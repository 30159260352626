body {
  font: normal 1em/1.5 $font;
  color: $black;
  letter-spacing: 1px;
  
  
}

/*h1, h2, h3 {
  font: normal 1.5em/2 $font;
  text-transform: uppercase;
  padding-top: 1.637em;
  margin: 0;
}*/

.logo {
  font: normal 1.5em/1.5 $title-font;
  text-transform: uppercase;
}

p, a {
  margin: 0;
  
}

h2, h3 {
  font: 700 20px/1.5 $font;
  margin: 0;
}

#symbols {
  display: none;
}

svg {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: $black;
  @include transition(all .5s);
  //padding: 4px 0px;
  
  /*&:hover {
    background-color: $blue;
    box-shadow: 6px 0 0 $blue, -6px 0 0 $blue;
  }*/
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 8px 0;
  
  &:first-child {
    margin-top: 0;
  }
}

button {
  background: none;
  border: none;
  @include appearance(none);
  padding: 0;
  margin: 0;
  
  &:focus {
    outline: none;
  }
}