.word-slider {
  b {
    font-weight: 400;
    padding-right: 12px;
  }
}

.cd-words-wrapper {
	display: inline-block;
	position: relative;
	text-align: left;

	b {
		display: inline-block;
		position: absolute;
		white-space: nowrap;
		left: 0;
		top: 0;

		&.is-visible {
			position: relative;
		}
	}
}

.cd-headline.clip {

	span {
		display: inline-block;
		padding: .2em 0;
	}

	.cd-words-wrapper {
		overflow: hidden;
		vertical-align: top;
		line-height: 1;

		&::after {
			/* line */
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 2px;
			height: 100%;
			background-color: $black;
		}
	}

	b {
		opacity: 0;

		&.is-visible {
			opacity: 1;
		}
	}
}