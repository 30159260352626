//header


.main-header {
  height:  100vh;
  width: 100%;
  position: relative;
  padding-top: 1px;
  box-sizing: border-box;
  background: $light-grey;
  overflow: hidden;
}


//slider

.slider {
  width: 80vmin;
  height: 80vmin;
  max-width: 800px;
  max-height: 800px;
  position: absolute;
  left: 50%;
  top: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  
  .slide-item{
    position: absolute;
    top: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    
    &.top {
      animation:anticlockwise 90s linear infinite;
    }
    
    &.bottom {
      animation:clockwise 90s linear infinite;
    }
  }
  
}


@keyframes clockwise { 100% { transform:rotate(360deg); } }
@keyframes anticlockwise { 100% { transform:rotate(-360deg); } }