
.main-footer{
  position: relative;
  z-index: 1;
  height: 150px;
  width: 100%;
  opacity: 1;
  visibility: visible;  
  
  color: $white;
  text-align: center;
  
 
  h3 {
    display: inline-block;
    font-weight: 400;
  }
  
  .arrow {
    display: block;
    width: 16px;
    height: 20px;
    position: absolute;
    right: 54px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    
    &:hover {
      animation: pulseup .9s infinite;
    }
    
    
    @include media($mobile){
      
      margin: 0 auto 30px;
      right: auto;
      top: auto;
      position: relative;
      transform: translateY(0) rotate(180deg);
      
    }
  }

}

.footer {
  
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 150px;
  z-index: 1;
  background: $black;
  color: $white;
  font-size: 12px;
  letter-spacing: .12em;
  display: table;
  table-layout: fixed;
  
  transition: transform .7s;
  transition-timing-function: cubic-bezier(.91,.01,.6,.99);


  .footer-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
}
  
