body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  
  &.loaded {
    height: auto;
    overflow: auto;
  }
  
  &.has-gallery {
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}


main {
  position: relative;
  z-index: 2;
  background: $white;
}

.preload {
  
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 2999;
  background: #f2f2f2;
  
  
  
}

.loader {
  width: 320px;
  height: 320px;
  margin: 0 auto;
  
  transform: scale(1.1);
  visibility: hidden;
  opacity: 0;
  
  @include media($mobile){
    width: 260px;
    height: 260px;
  }
}


.info {
  min-height: 100vh;
  display: table;
  box-sizing: border-box;
  //max-width: 980px;
  margin: 0 auto;
  padding: 0 10%;
  
  @include media($mobile){
    min-height: auto;
    padding: 40px 24px 0;
  }
  
  .row {
    display: table-row;
    .cell { 
      display: table-cell;
      vertical-align: middle;
    }
    
    &.arrow-row {
      height: 100px;
    }
  }
  
  p {
    font-size: 20px;
    
    @include media($mobile){
      padding: 20px 0;
      font-size: 14px;
    }
  }
  
  h1 {
    font-size: 4.5vw;
    font-weight: 700;
    line-height: 1.2;
    margin: 1em 0;
    
    @include media($mobile){
      font-size: 24px;
    }
    
    mark {
      font-style: normal;
      background: linear-gradient(transparent 14%, $lightblue 14%, $lightblue 84%, transparent 84%);
      //padding: 0 8px;
      //box-shadow: 4px 0 0 $lightblue, -4px 0 0 $lightblue;
      
    }
  }
  
  .arrow {
    margin-bottom: 30px;
    display: block;
    width: 30px;
    height: 40px;
    
    &:hover {
      animation: pulsedown .9s infinite;
    }
  }
}




.data {
  width: 100%;
  display: flex;
  
  
  @include media($mobile){
    display: block;
  }
  
  .row {
    padding: 80px 54px;
    display: flex;
    flex-flow: column wrap;
    
    @include media($mobile){
      padding: 40px 24px;
    }
    
  }
  
  .about {
    background: #e6e6e6;
  }
  
  .location {
    @include media($large){
      padding: 140px 54px;
    }
  }
  .availability {
    background: $black;
    color: $white;
    
    background: $green;
    
    @include media($large){
      padding: 140px 54px;
    }
  }
  .follow {
    background: #cccccc;
    padding: 30px 54px;
    
    @include media($mobile){
      padding: 30px 24px;
    }
    
    a {
      display: block;
      position: relative;
      
      &:hover {
        .arrow {
          animation: pulsetoright .5s infinite;
        }
      }
    }
    
    .arrow {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translate(0, -50%) rotate(270deg);
    }
    
  
  }
  
  
  
  .section {
    width: 50%;
    
    @include media($mobile){
      width: 100%;
    }
    
  }
}


.but {
  position: relative;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: right .3s;
  z-index: 10;
  
  svg {
    transform: rotate(270deg);
    transform-origin: center center;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
   
  }
}

@keyframes pulsetoright {
  0% {
    transform: translate(0, -50%) rotate(270deg);
  }
  50% {
    transform: translate(30%, -50%) rotate(270deg);
  }
  100% {
    transform: translate(0, -50%) rotate(270deg);
  }
}


@keyframes pulseup {
  0% {
    transform: translateY(-50%) rotate(180deg);
  }
  50% {
    transform: translateY(-100%) rotate(180deg);
  }
  100% {
    transform: translateY(-50%) rotate(180deg);
  }
}
@keyframes pulsedown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0);
  }
}



