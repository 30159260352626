.work {
  background: #f2f2f2;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
 
  
  @include media($mobile){
    height: auto;
  }
}

.draggable {
  display: inline-block;
  position: absolute;
  
  .title {
    display: inline-block;
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 15px;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    color: $white;
   
  }
  
  @include media($mobile){
    position: relative;
    display: block;
    margin: 0 0 0;
    
    img {
      display: block;
    }
  }
}

.video-gallery {
  
  position: relative;
  background: $black;
  
  .arrow {
    bottom: auto;
    top: 30px;
    
    @include media($mobile){
      display: none;
    }
  }
  
  .gallery-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .video-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}


.image {
  display: inline-block;
  position: absolute;
  
  
  img {
    width: 100%;
  }
  
  @include media($mobile){
    position: relative;
    display: block;
  }
    
  .arrow-left {
    color: $white;
    display: inline-block;
    width: 26px;
    height: 48px;
    position: absolute;
    z-index: 30;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: transform .5s;
   
  }
  
  .arrow-right {
    color: $white;
    display: inline-block;
    width: 26px;
    height: 48px;
    position: absolute;
    z-index: 30;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: transform .5s;
  }
  
  .arrow {
    color: $white;
    //mix-blend-mode: difference;
    display: inline-block;
    width: 30px;
    height: 40px;
    position: absolute;
    z-index: 30;
    left: 30px;
    top: 30px;
    transform: rotate(0);
   
    
    svg {
      position: absolute;
      left: 0;
      top: 0;
      transform-origin: center center;
    }
    
    
    .scale{
      
      transform: rotate(0deg);
      opacity: 1;
      transition: transform .9s, opacity .9s;
    }
    
    .cross {
      transform: rotate(0deg);
      opacity: 0;
      transition: transform .9s, opacity .9s;
    }
    
    &:hover {  
     animation: pulse .5s infinite;
    }
    
    @include media($mobile){
      display: none;
    }
    
    &.clicked {
      
      .scale{
        transform: rotate(0deg);
        opacity: 0;
      }
      
      .cross {
        transform: rotate(0deg);
        opacity: 1;
      }
      
    }
    
  }

  
  &.gallery-opened {
  
    background: $black;
    
    .title {
      opacity: 1;
      visibility: visible;
    }
    
    .arrow-left, .arrow-right {
      visibility: visible;
      opacity: 1;
    }
    
    .swiper-slide {
      width: 80%;
      position: relative;
    }
    
    .swiper-slide-prev, .swiper-slide-next {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        z-index: 4;
        transition: background .81s;
        cursor: pointer;
      }
      
      &:hover {
        &:after { 
          background: rgba(0, 0, 0, .2);
        }
      
      }
    }
    
    img {
      /*position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      
      min-width: 100%;
      min-height: 100%;
      max-width: none;
      max-height: none;
      width: auto;
      height: auto;*/
    }
  }
  
  
  
}

/*@media (min-aspect-ratio: 16/9){
  .image.gallery-opened img {
      max-width: 100%;
  }  
}

@media (max-aspect-ratio: 16/9) {
  .image.gallery-opened img {
      max-height: 100%;
  }
}*/

.text {
  display: inline-block;
  position: absolute;
  
  @include media($mobile){
    position: relative;
  }
  
}


.more {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #cccccc;
  box-sizing: border-box;
  padding: 60px 90px 40px 50px;
  transform: translate(100%, 0);
  transition: transform .3s;
  
  
  @include media($mobile){
    width: 100%;
    padding: 60px 50px 40px 20px;
    box-sizing: border-box;
  }
  
  a {
    line-height: 70px;
    color: $white;
    background: $black;
    display: block;
    padding: 0 20px;
    width: 240px;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      width: 40px;
      height: 100%;
      top: 0;
      display: block;
      background: $black;
      transform: scaleX(0);
      transition: transform .3s;
      transform-origin: left top;
    }
    
    .icon {
      right: 10px;
    }
    
    &:hover {
      
      .icon {
        right: -30px;
      }
      
      &:after {
        content: '';
        transform: scaleX(1);
      }
    }
  }
  
  
  &.is-visible {
    display: block;
    transform: translate(0, 0);
  }
}




.more-stuff {
  color: $black;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  position: absolute;
  bottom: 4vh;
  right: 8vw;
  
  @include media($mobile){
    position: relative;
    bottom: auto;
    right: auto;
    margin: 2em 0 2em 24px;
    
  }
  
  &:after {
    content: "";
    display: block;
    height: 6px;
    background: $black;
    width: 100%;
    transition: transform .3s;
    transform-origin: left top;
    transform: scaleX(.4);
  }
  
  &:hover {
    &:after { 
      transform: scaleX(1);
    }
  }
}

iframe {
  margin: 0;
  padding: 0;
  width: 300px;
  
  @include media($mobile){
    width: 100%;
  }
}

.work-title {
  background: #ff6d90;
  width: 280px;
  height: 70px;
  color: $white;
  text-align: center;
  line-height: 70px;
  margin: 0;
  z-index: 2000;
  box-sizing: border-box;
  //mix-blend-mode: difference;
  
  h2{
    line-height: 70px;
    font-weight: 300;
  }
  
  &.playlister {
    background: #55acee;
    width: 280px;
  }
  
  @include media($mobile){
    margin: 0 0 0;
    width: 100%;
    
    &.playlister {
      width: 100%;
      margin-bottom: 1em;
    }
  }
}

.playlist {
  position: absolute;
  bottom: 2vh;
  left: 4vw;
  height: 400px;
  width: 300px;
  
  @include media($mobile){
    position: relative;
    
    bottom: auto;
    left: auto;
    
    width: 100%;
  }
  
  .playlist-header {
    height: 20px;
    background: $blue;
  }
  

  
  .spotify {
    width: 300px;
    height: 380px;

    @include media($mobile){
      width: 100%;
    }
  }
  
}



.gallery {
  background: rgba($black, 0);
  transition: background 2s;
  
  
}

#el1 {
  left: 12vw;
  top: 8vh;
}

#el2 {
  left: 43vw;
  top: 8vh;
  width: 45vw;
  height: 31.5vw;

  @include media($mobile){
    height: auto;
  }
  
}

#el3 {
  left: 50.7vw;
  bottom: 8vh;
  width: 29.6vw;
  height: 20.7vw;
  
  @include media($mobile){
    height: auto;
  }
}

#el4 {
  
  left: 19.5vw;
  top: 27vh;
  width: 37.5vw;
  height: 26.25vw;
  
  @include media($mobile){
    height: auto;
  }
}

#el5 {
  left: 27.5vw;
  bottom: 8vh;
}






@include media($mobile){
  #el1,#el2, #el3, #el4, #el5 {
    left: auto;
    top: auto;
    bottom: auto;
    width: 100%;
  }
}

@keyframes pulseright {
  0% {
    transform: translateX(0%) rotate(270deg);
  }
  50% {
    transform: translateX(30%) rotate(270deg);
  }
  100% {
    transform: translateX(0%) rotate(270deg);
  }
}

@keyframes pulse {
  0% {
    transform:scale(1) rotate(0);
  }
  50% {
    transform: scale(1.1) rotate(0);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}