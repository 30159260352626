.message-sent {
  display: none;
}

.form-sent {
  .send-message {
    display: none;
  }
  .message-sent {
    display: block;
  }
  
  .more {
    display: none;
  }
}

#contact-form {
  //max-width: 400px;
  opacity: 1;
  visibility: visible;
  transition: opacity .51s ease-out, visibility .51s ease-out, transform .51s  ease-out;
  
 
  
  &.done {
    
    opacity: 0;
    visibility: hidden;
    transform: translateY(100px);
    
    
  
  }
  
}

.contact-form {
  height: auto;
  background: #eee;
  padding:0 80px 20px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  
  .confirmation {
    opacity: 0;
    height: 0;
    position: absolute;
    top: 50%;
    font-size: 24px;
    visibility: hidden;
    transform: translateY(-100px);
    transition: opacity .51s ease-out, visibility .51s ease-out, transform .51s  ease-out;
  }
  
  &.done {
    .confirmation { 
      opacity: 1;
      visibility: visible;
      transform: translateY(-50%);
      height: auto;
    }
  }
  
  
  @include media($mobile){
    padding: 40px 20px 60px;
  }
  
  
}


.contact-form{
  
@include clearfix;

::-webkit-input-placeholder {color:#000000;}
::-moz-placeholder          {color:#000000;} /* Firefox 19+ */
:-moz-placeholder           {color:#000000;} /* Firefox 18- */
:-ms-input-placeholder      {color:#000000;} 


.fieldset {

	padding: 25px 0 0px;
	border-bottom: 2px solid $black;
	position: relative;
	
	&.has-text {
	  border-bottom: 2px solid transparent;
	}
	
	&.submit {
	  border-bottom: none;
	  padding-top: 80px;
	}
	
}  


.input {
  font-size: 20px;
  font-family: $font;
  height:56px;
  transition: background .51s, border .3s;
  background:$lightgrey;
  
}

.has-text .input {
  font-size: 20px;
  font-family: $font;
  text-transform: none;
  letter-spacing: 0;
  background: $white;
  padding-left: 12px;
}



  	  
.ctrl {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  background: transparent;
  border: none;
  outline: none;
  transition: background .51s, border .3s;

  &.select-ctrl {
    background:transparent url('/images/select.svg') no-repeat 100% center;
  }


  &:focus{
    outline: none;
  }
}

.has-text {
 .ctrl {
     background:$white;
  }
  
  .select-ctrl {
    background:$white url('/images/select.svg') no-repeat 97% center;
  }
}
  
  
input[type="text"], input[type="email"], input[type="tel"] {
	margin: 0;
	padding: 0;
	color: $black;
	background: transparent;
	width: 100%;
	padding: 12px 20px 13px 0;
	border: none;

	&:focus {
		border-color: none;
		outline: none;
	}
	
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	
}

select {

  appearance: none;
  padding: 13px 20px 12px 0;
  border: none;
  color: $black;
  transition: background .51s, border .3s;
  
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  
  option:not(:checked){
    color: $black;
  }
  
  option:checked{
    color: $black;
  }
 }
   
 button {
   appearance: none;
   border: none;
   background: none;
   outline: none;
   
   &:focus {
     outline: none;
   }
 }
 
 .but {
   background: $black;
   color: $white;
   font-size: 20px;
   padding: 18px;
   text-align: left;
   width: 160px;
   transition: width .3s;
   
   .icon{
     right: 8px;
   }
   
   &:hover {
     width: 200px;
   }
   
 }
 
 
 textarea {
   padding-top: 17px;
   padding-left: 0;
   transition: background .51s, border .3s;
   

 }
 
 .has-text {
   textarea {
      height: 120px;
   }
 }
 
 .dot {
   width: 8px;
   height: 8px;
   display: block;
   border-radius: 50%;
   
   position: absolute;
   right: 0;
   bottom: 26px;
 }
  
  
  
.invalid[required], input.invalid[required], .error[required], input.error[required] {
  
  -moz-box-shadow: none;
  
  & + .dot {
    background: $invalid;
  }
  
}

.valid[required], input.valid[required] {
  
  -moz-box-shadow: none;
  & + .dot {
    background: $valid;
  }

}

.required[required], input.required[required] {
  
  -moz-box-shadow: none;
  & + .dot {
    background: $invalid;
  }
  
}

input:required, select:required, textarea:required {
    box-shadow:none;
    -moz-box-shadow: none !important;
}
input:invalid, select:invalid, textarea:invalid {
    box-shadow:none;
    -moz-box-shadow: none !important;
}
  

.formerror {
	margin: .2em;
	font-size: 12px;
	font-weight: 400;
	color: $invalid; 
  padding: 0 6.35987% 0 0;
	display: none;

	&.is-error {
		display: block;
	}
}




}

