#menuBtn {

  position: absolute;
  right: 54px;
  top: 40px;
  z-index: 100;
  
  &:focus {
    outline: none;
  }
  
  @include media($mobile){
    right: 24px;
    top: 30px;
    
    transform-origin: right top;
    transform: scale(.8);
  }
  
}


.has-gallery {
  .main-nav {
    z-index: 0;
  }
  
}

.main-nav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  
  a {
    font-size: 30px;
    margin-right: 36px;
    color: $white;
    font-weight: 300;
    display: inline-block;
    
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background: $white;
      transform: scaleX(0);
      transition: transform .3s;
    }
    
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
    
    @include media($mobile){ 
      margin-right: 0;
      
      margin: 16px 0;
    }
  }
  
}

.nav {
  position: absolute;
  right: 0;
  top: 0;
  height: 120px;
  padding:34px 120px 0 54px;
  z-index: 99;
  background: #88c5f3;
  transform: translateX(100%);
  transition: transform .5s cubic-bezier(.91,.01,.6,.99);
  
  .is-open & {
    transform: translateX(0);
  }
  
  @include media($mobile){
    
    width: 100%;
    height: 100vh;
    padding:0;
    
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
}

.logo {
   position: absolute;
   left: 54px;
   top: 28px;
   font-size: 36px;
   
   a {
     color: $black;
     font-weight: 400;
     font-size: 36px;
     margin: 0;
     padding: 0;
     
     
     &:after {
       display: none;
     }
   }
   
   @include media($mobile){
     left: 24px;
     top: 20px;
     font-size: 32px;
   }
}



  $hamburger-padding-x                       : 0 !default;
  $hamburger-padding-y                       : 0 !default;
  $hamburger-layer-width                     : 50px !default;
  $hamburger-layer-height                    : 2px !default;
  $hamburger-layer-spacing                   : 12px !default;
  $hamburger-layer-color                     : $black !default;
  $hamburger-layer-border-radius             : 0px !default;
  $hamburger-hover-opacity                   : 0.7 !default;
  $hamburger-hover-transition-duration       : 0.1s !default;
  $hamburger-hover-transition-timing-function: linear !default;
  
  // To use CSS filters as the hover effect instead of opacity,
  // set $hamburger-hover-use-filter as true and
  // change the value of $hamburger-hover-filter accordingly.
  $hamburger-hover-use-filter: false !default;
  $hamburger-hover-filter    : opacity(50%) !default;
 
  
  
  
  .hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x 7px;
    display: inline-block;
    cursor: pointer;
  
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;
  
    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  
    /*&:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-hover-filter;
      }
      @else {
        opacity: $hamburger-hover-opacity;
      }
    }*/
  }
  
  .hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
  }
  
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    
  
    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: transform, width;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    
    & {
      width: 32px;
      transform: rotate(0) scaleX(75%);
    }
  
    &::before,
    &::after {
      content: "";
      display: block;
    }
  
    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  
    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }

  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.1s 0.14s ease,
                    opacity 0.1s ease;
      }

      &::after {
        transition: bottom 0.1s 0.14s ease,
                    transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        width: $hamburger-layer-width;
        
        background-color: $white;

        &::before {
          top: 0;
          opacity: 0;
          background-color: $white;
          transition: top 0.1s ease,
                      opacity 0.1s 0.14s ease;
        }

        &::after {
          bottom: 0;
          background-color: $white;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease,
                      transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }