$white: #ffffff;
$black: #000000;
$grey: #cccccc;
$light-grey: #f2f2f2;
$blue: #55acee;
$lightgrey: #e6e6e6;
$lightblue: #ddeefc;
$green : #3ed7ca;

$invalid: #db4c70;
$valid: #4cdbb7;

$max-width: 1280px;
$section-padding: 30px 100px;
$large-padding: 30px;

$font:  "Poppins", sans-serif;
$title-font:  "Oswald", sans-serif;

$mobile: new-breakpoint(max-width 767px 16);
$tablet: new-breakpoint(max-width 992px 16);
$ipad: new-breakpoint(max-width 992px 16);
$large: new-breakpoint(min-width 1600px 16);